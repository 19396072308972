export const corousselDetail = [
  {
    id: 1,
    url: "./img/nav_image/bio_img.jpg",
    alt: "bio_indus",
  },
  {
    id: 2,
    url: "./img/nav_image/env_img.jpg",
    alt: "env_indus",
  },
  {
    id: 3,
    url: "./img/nav_image/ind_img.jpg",
    alt: "ind_indus",
  },
  {
    id: 4,
    url: "./img/nav_image/life_img.jpg",
    alt: "life_indus",
  },
  {
    id: 5,
    url: "./img/nav_image/water_img.jpg",
    alt: "water_indus",
  },
];

export const productsExtractedData = [
  { tag: "Deep Freezer", description: "ULT freezer (-86℃)" },
  {
    tag: "Portable Cooler/Mobile Cold Chain",
    description: "Portable -80degC freezer",
  },
  {
    tag: "Portable Cooler/Mobile Cold Chain",
    description: "Fan cooling portable vaccine cooler",
  },
  { tag: "0", description: "SUPER-E Microwave Digestion/Extraction System" },
  { tag: "Colonometer", description: "INR-1101 Automatic colony counter" },
  { tag: "Colonometer", description: "Touch probe colony counter—INJ-2/INJ-3" },
  {
    tag: "Incubator",
    description: "Efficient Medicine Stability Test Chamber",
  },
  {
    tag: "Incubator",
    description: "IMS/IMS-A series Medicine Stability Test Chamber",
  },
  { tag: "Rotary Evaporator", description: "Pilot-scale Rotary Evaporator" },
  { tag: "Rotary Evaporator", description: "Lab-scale Rotary Evaporator" },
  { tag: "Pharma Industry", description: "Moore Osmotic Concentration Tester" },
  { tag: "Pharma Industry", description: "Particle Analyzer" },
  { tag: "Imaging System", description: "Multifunctional-Imager" },
  { tag: "Imaging System", description: "Gel doc system" },
  { tag: "Imaging System", description: "Chemiluminescence Imager" },
  { tag: "Wash & Clean", description: "Industrial Ultrasonic Cleaner" },
  {
    tag: "Bio-fermentation Equipment",
    description: "Bioreactor/Fermenter for Cultivated Meat",
  },
  { tag: "Microplate Reader", description: "Microplate Reader" },
  {
    tag: "Bio-fermentation Equipment",
    description: "Benchtop Cell Bioreactor",
  },
  { tag: "Centrifuge", description: "Blood Bank Centrifuge" },
  {
    tag: "Low Temperature Preservation",
    description: "Dual Sensors Pharmacy/Vaccine Refrigerator",
  },
  { tag: "Air Clean Equipment", description: "Pass Box" },
  { tag: "Air Clean Equipment", description: "Sampling Booth" },
  {
    tag: "Climatic Simulation Equipment",
    description: "Temperature Humidity Chamber",
  },
  {
    tag: "Cryogenic Preservation",
    description: "CryoGuard Series, with Temp. & Liquid Level Monitor",
  },
  { tag: "Imaging System", description: "Horizontal Electrophoresis Cell" },
  {
    tag: "Low Temperature Preservation",
    description: "Dual Cooling System ULT freezer (-86℃)",
  },
  { tag: "Autoclave", description: "Touch Screen Vacuum Autoclave(T series)" },
  { tag: "Low Temperature Preservation", description: "ULT freezer (-86℃)" },
  {
    tag: "Microwave Digestion Equipment",
    description: "SUPER-E Microwave Digestion/Extraction System",
  },
  {
    tag: "Climatic Simulation Equipment",
    description: "Salt Spray Cycling Corrosion Test Cabinet",
  },
  {
    tag: "Biosafety Laboratory Room",
    description: "Biosafety laboratory (BSL-1/2/3/4)",
  },
  { tag: "Imaging System", description: "Vertical Electrophoresis Cell" },
  { tag: "Imaging System", description: "Trans-Blotting Electrophoresis Cell" },
  {
    tag: "Bio-fermentation Equipment",
    description: "Benchtop Duplex Bioreactor",
  },
  {
    tag: "Water Purification System",
    description:
      "Nova-Smart EU/U ASTM Type I &Type II / Clinical Laboratory Reagent Water (CLSI)",
  },
  {
    tag: "Imaging System",
    description: "WB-Protein and Transfer Electrophoresis Cell",
  },
  {
    tag: "Bio-fermentation Equipment",
    description: "Benchtop Multiple Bioreactor-MINI",
  },
  {
    tag: "Low Temperature Preservation",
    description: "Portable -80degC freezer",
  },
  { tag: "Imaging System", description: "Electrophoresis Power Supply" },
  { tag: "Imaging System", description: "Transilluminator" },
  {
    tag: "Bio-fermentation Equipment",
    description: "Stainless Steel Multiple Fermenter",
  },
  {
    tag: "Low Temperature Preservation",
    description: "Fan cooling portable vaccine cooler",
  },
  {
    tag: "General Laboratory Instruments",
    description: "Mini Real-time PCR System IRTP 16",
  },
  { tag: "Bio-fermentation Equipment", description: "Single-Use Bioreactor" },
  {
    tag: "Refrigerated/Heating Circulator",
    description: "Recirculating Chiller",
  },
  { tag: "Anaerobic Incubator", description: "Anaerobic Incubator" },
  {
    tag: "Shaking Incubator/Incubator",
    description: "Ultimate-Cell Stackable Shaking Incubator",
  },
  {
    tag: "Pharmacy/Vaccine Refrigerator",
    description: "Dual Sensors Pharmacy/Vaccine Refrigerator",
  },
  { tag: "Deep Freezer", description: "ULT freezer (-86℃)" },
  {
    tag: "Portable Cooler/Mobile Cold Chain",
    description: "Portable -80degC freezer",
  },
  {
    tag: "Portable Cooler/Mobile Cold Chain",
    description: "Fan cooling portable vaccine cooler",
  },
];
