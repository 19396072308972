export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <h1 className="skills-section--heading">About Us</h1>
      <p className="hero--section-description">
        At <strong>LUNA Scientific Solutions</strong>, we understand the crucial
        role that innovative tools and reliable support play in driving
        scientific progress. Whether you are conducting groundbreaking research,
        developing new technologies, or ensuring the quality and safety of your
        products, our mission is to empower you with the resources and expertise
        you need to succeed. With a comprehensive range of high-quality
        scientific instruments spanning analytical chemistry, life sciences,
        materials characterization, and environmental monitoring, we offer
        solutions that are at the forefront of technology and innovation. From
        state-of-the-art Centrifuges and Hysto-pathology systems to advanced
        microscopy and molecular biology tools, our product portfolio is
        carefully curated to meet the evolving demands of modern science. But
        our commitment to excellence doesn't end with the delivery of
        instruments. At LUNA Scientific Solutions, we pride ourselves on
        delivering unparalleled customer service and support every step of the
        way. From personalized consultations and training sessions to
        calibration, maintenance, and technical assistance, our team of experts
        is dedicated to ensuring that you derive maximum value from your
        investment and achieve your scientific goals with confidence. We believe
        that collaboration is key to driving meaningful progress in science and
        technology. As such, we are always eager to explore new partnerships,
        collaborations, and opportunities for mutual growth and innovation.
        Whether you are a researcher in academia, a scientist in industry, or an
        educator shaping the future generation of innovators, we invite you to
        join us on this exciting journey towards a brighter, more informed
        future. I would be thrilled to discuss how LUNA Scientific Solutions can
        support your specific needs and objectives. Please feel free to reach
        out to us directly to schedule a consultation or learn more about our
        products and services.
      </p>
    </section>
  );
}
