import { productsExtractedData } from "../../data/data";
import data from "../../data/index.json";

export default function MyPortfolio() {
  return (
    <section className="portfolio--section" id="Products">
      <div className="portfolio--container-box">
        {/* <div className="portfolio--container"> */}
        {/* <p className="sub--title">Recent Projects</p> */}
        <h2 className="section--heading">Our Products</h2>
        {/* </div> */}
      </div>
      <div className="portfolio--section--container">
        {productsExtractedData?.map((item, index) => (
          <div key={index} className="portfolio--section--card">
            <div className="portfolio--section--img">
              <img src={item?.src} alt="Placeholder" />
            </div>
            <div className="portfolio--section--card--content">
              <div>
                <h3 className="portfolio--section--title">
                  {item?.description}
                </h3>
                {/* <ul className="flex flex-wrap mt-4 gap-2 sm:mt-auto">
                  <li>
                    {item?.tag}
                  </li>
                </ul> */}
                {/* <p className="text-md">{item?.description}</p> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
