import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { corousselDetail } from "../../data/data";

export default function HeroSection() {
  return (
    <section id="heroSection" className="hero--section">
      <Carousel infiniteLoop autoPlay showThumbs={false} showStatus={false}>
          {corousselDetail.map((el) => (
            <div key={`id_${el.id}`}>
              <img src={el.url} alt={el.alt} />
            </div>
          ))}
        </Carousel>
    </section>
  );
}
